import React from "react";
import { Page } from "@/components/Page";
import { withAuthentication } from "@/features/Authentication/AccessRestriction/withAuthentication";
import { TestApplicationPlayground } from "@/features/Application/TestApplicationPlayground";

function PlaygroundPage() {
  return (
    <Page variation="base" fullwidth>
      <TestApplicationPlayground />
    </Page>
  );
}

export default withAuthentication(PlaygroundPage);
