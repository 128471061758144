import { useEffect, useState } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";

export function useJobberAccessToken() {
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken"),
  );
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const authCode = getAuthCode();

  useEffect(() => {
    if (!refreshToken && authCode) {
      getAccessToken(authCode).then(data => storeTokens(data));
    } else if (refreshToken) {
      getAccessTokenFromRefresh(refreshToken).then(data => storeTokens(data));
    }
  }, []);

  async function getAccessToken(code: string) {
    const accessTokenParams = initAccessTokenParams();
    accessTokenParams.append("grant_type", "authorization_code");
    accessTokenParams.append("code", code);

    const response = await fetch(
      `${
        process.env.GATSBY_JOBBER_URL
      }/api/oauth/token?${accessTokenParams.toString()}`,
      {
        method: "POST",
      },
    );
    return response.json();
  }

  async function getAccessTokenFromRefresh(token: string) {
    const accessTokenParams = initAccessTokenParams();
    accessTokenParams.append("grant_type", "refresh_token");
    accessTokenParams.append("refresh_token", token);

    const response = await fetch(
      `${
        process.env.GATSBY_JOBBER_URL
      }/api/oauth/token?${accessTokenParams.toString()}`,
      {
        method: "POST",
      },
    );

    return response.json();
  }

  function initAccessTokenParams() {
    const accessTokenParams = new URLSearchParams();
    accessTokenParams.append(
      "client_id",
      `${localStorage.getItem("clientId")}`,
    );
    accessTokenParams.append(
      "client_secret",
      `${localStorage.getItem("clientSecret")}`,
    );
    return accessTokenParams;
  }

  function getAuthCode() {
    /* istanbul ignore if */
    if (window === undefined) {
      return false;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get("code");
  }

  function storeTokens(data: { access_token: string; refresh_token: string }) {
    setAccessToken(data.access_token);
    setRefreshToken(data.refresh_token);
    localStorage.setItem("refreshToken", data.refresh_token);
    const decodedJwt =
      jwtDecode<JwtPayload>(data.access_token || "") || undefined;
    if (decodedJwt.exp) {
      // Refresh the token one minute before expiry just to be safe
      const accessTokenExp = decodedJwt.exp - Date.now() / 1000 - 60;
      setTimeout(
        token => {
          getAccessTokenFromRefresh(token).then(response =>
            storeTokens(response),
          );
        },
        accessTokenExp * 1000,
        refreshToken,
      );
    }
  }

  return { accessToken };
}
